.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.2s ease-in-out;
    right: 60px; /* Coloca el botón a la izquierda del botón de WhatsApp */
  }
  
  .floating-button:hover {
    transform: scale(1.1);
  }


  