/* index.css */
@font-face {
  font-family: 'GothamBook'; /* Nombre que usarás en el CSS */
  src: url('./assets/fonts/GothamBook.ttf') format('truetype');
  font-weight: normal; /* Puedes especificar normal, bold, etc. */
  font-style: normal; /* Puedes especificar italic, oblique, etc. */
}

@font-face {
  font-family: 'GothamBlack';
  src: url('./assets/fonts/GothamBlack.woff') format('woff');
  font-weight: normal; /* Puedes especificar normal, bold, etc. */
  font-style: normal; /* Puedes especificar italic, oblique, etc. */  
}

/* Reseteo de estilo básico */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Cuerpo principal */
body {
  background-color: #ffffff;
  display: flex;

  flex-direction: column;
  line-height: 1.8;
  text-align: justify; 

}



/* Barra de navegación superior */
.navbar {
  width: 100%;
  height: 60px;
  background-color: #202124;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;  /* Asegura que la barra de navegación esté por encima del contenido */
}


/* Contenedor principal */
.main-content {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0px;  /* Ajuste para asegurar que el contenido no quede oculto detrás de la navbar */
  margin-left: 240px;
  position: relative; /* Asegura que el contenedor se ajuste correctamente */
  z-index: 1;  /* Asegura que el contenido se apile correctamente */
}

.footer {
  width: calc(100%); /* Ajusta al ancho disponible */
  background-color: #202124;
  color: #ffffff;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  font-family: 'GothamBook', sans-serif;
}


.main-content {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0px;
  margin-left: 240px;
  margin-bottom: 60px; /* Espacio suficiente para el footer */
  position: relative;
  z-index: 1;
  margin-bottom: 70px; /* Espacio adicional para el footer */
}


/* Ajuste para el Navbar en dispositivos móviles */
@media (max-width: 768px) {
  .navbar ul {
    display: block;
    text-align: center;
  }

  .navbar ul li {
    margin-left: 0;
  }


  .footer {
    width: 100%;
    left: 0; /* No deja espacio para el menú lateral */
  }

  .main-content {
    margin-top: 10px;  /* Asegura que el contenido no quede oculto detrás de la navbar */
    margin-bottom: 80px; /* Espacio para el footer */
    margin-left: 60px; /* Evita margen para el menú lateral */
  }  
}
